import { useParams } from '@solidjs/router';
import { PostRevisionStatus } from '~/__gql-generated__/graphql';

import PostList from '~/components/PostList';

export default function Tag() {
  const parameters = useParams();

  return (
    <PostList
      filter={{
        revisionsConnection_SOME: {
          edge: { status: PostRevisionStatus.Current },
          node: { tags_SOME: { slug: parameters.tag } },
        },
      }}
    />
  );
}
